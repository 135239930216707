/**
 * API URLS
 */
export const urls = {
  filePath: 'https://s3.amazonaws.com/media.tryloop.co/',
  createStore: '/auth/store/create/account',
  storeOtpVerification: '/auth/store/login/signup/otp/verify',
  resendOtp: '/auth/store/login/signup/otp/resend',
  createCard: '/api/store/card/create',
  savedCards: '/api/store/cards',
  defaultCard: '/api/store/card/default',
  deleteCard: '/api/store/card/delete',
  storeLogin: '/auth/store/login/account',
  storeDetails: '/api/store/details',
  storeUpdate: '/api/store/update',
  changePassword: '/store/api/reset/store/password',
  setNewPassword: '/api/store/create/new/password',
  forgetPassword: '/auth/store/forget/password',
  createOrder: '/store/create/order',
  storeOrders: '/api/store/orders',
  orderDetails: '/api/store/orders/detail',
  uploadFiles: '/api/upload/files',
  register: '/auth/api/user/signup',
  application: '/application',
  driverRating : '/api/store/rating',
  packageList: '/store/package/list',
  ordersCharts : '/api/store/chart',
  vehicleOptions: '/api/store/vehicles/options',
  walletList: '/store/wallet/list',
  createStaff: '/api/store/staff/registration',
  staffList: '/api/store/staff/list',
  deleteStaff: '/api/store/staff/delete',
  staffDetails: '/api/store/staff/detail/',
  updateStaff: '/api/store/staff/update',
  storeMoneyWithdrawal: '/api/store/withdrawal/request',
  walletTopUp: '/api/store/add-amount', 
  paymentStatus: '/api/store/payment-status',
  vehiclePriceList: '/api/store/vehicle/list',
  boxPriceList: '/api/store/boxsize/list',
  countryList: '/api/store/country/list',
  trackOrder: '/api/track-order/',
  orderCanel: '/api/store/order/store-cancel',
  orderRetry:  '/api/store/order/retry',

  // admin url,s
  adminLogin: '/auth/admin/login',
  users: '/admin/users',
  getOrderDetail: '/admin/detail/order',
  getStoreDetail: '/admin/detail/store',
  getDriverDetail: '/admin/detail/user',
  getStoreList: '/admin/search/store/list',
  getDriverList: '/admin/search/user/list',
  getOrderList: '/admin/search/order/list',
  getPackageList: '/admin/search/package/list',
  cancelOrder: '/admin/order/cancel',
  cancelDriver: '/admin/user/cancel',
  changeStoreStatus: '/admin/store/update',
  getVehicleTypes: '/admin/vehicle/types',
  addVehicleAmount: '/admin/add/vehicle',
  showVehicles: '/admin/show/vehicle',
  editVehicle: '/admin/update/vehicle',
  deleteVehicle: '/admin/delete/vehicle',
  addVehicleList: '/admin/create/vehicle/list',
  vehicleList: '/admin/vehicle/list',
  deleteVehicleTypes: '/admin/delete/vehicles',
  deletePackage :  '/admin/delete/package',
  updatePackage: '/admin/updatePackage',
  getReason : '/admin/reason/list',
  addReason: '/admin/add/reason',
  addPackage: '/admin/package',
  user: '/admin/user',
  ordersCancellation: '/admin/cancellation/list',
  updateCanellationStatus: '/admin/update/cancel/status',
  moneyWithdrawal : '/admin/money/request/list',
  updateMoneyRequestStatus : '/admin/money/request',
  countires:'/admin/country/list',
  deleteCountry: '/admin/country/delete',
  addCountries: '/admin/add/currencies',
  updateCountry: '/admin/country/update',
  updateStorePersonalDetails: '/admin/store/personal/detail/update',
  storeMoneyRequestList: '/admin/store/money/request/list',
  updateStoreMoneyRequest: '/admin/store/money/request',
  transcationList: '/admin/store/wallet-topup/list',
  createAdminStaff: '/admin/new/staff', 
  adminStaffList: '/admin/staff/list',
  editStaff: '/admin/edit/staff',
  resetPassword: '/admin/change/password',
  addMoneyToStore: '/admin/store/add/amount',
  addMoneyToDriver: '/admin/driver/add/amount',
  totalActiveDriver: '/admin/total/active/driver',
  totalStore: '/admin/total/store',
  totalDriver: '/admin/total/driver',
  totalTodayOrders: '/admin/total/today/order',
  totalOrders: '/admin/count/total/order',
  updateRewardAmount:'/admin/update/reward/amount',
  referralReward: '/admin/referral/reward',
  cashFlow: '/admin/order/record/list',
  boxPrice: '/admin/boxsize/list',
  boxPriceAdd: '/admin/create/boxsize',
  boxPriceDelete: '/admin/delete/boxSize',
  updateBoxPrice: '/admin/update/boxsize',
  profitSharePer: '/admin/profit/share/list',
  profitSharePerUpdate: '/admin/update/profit/share/percentage',
  csvDriverList: '/admin/driver/csv',
  csvOrderList: '/admin/order/csv',
  csvStoreList: '/admin/store/csv',
  csvEarningList: '/admin/orderRecord/csv',
  orderGraph: '/admin/total/orders',
  revenueGraph: '/admin/total/revenue'

}

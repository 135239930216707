import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { StoreService } from "src/app/core/services/store/store.service";

@Component({
  selector: "app-store-sidebar",
  templateUrl: "./store-sidebar.component.html",
  styleUrls: ["./store-sidebar.component.css"],
})
export class StoreSidebarComponent implements OnInit {
  activeRoute: any;
  isActive: boolean = false;
  public roles = {
    admin: false,
    staff: false
  }

  constructor(
    private authService: AuthenticationService,
    private route: Router,
    private router: ActivatedRoute,
    private storeService: StoreService
  ) {
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((res: any) => {
        let path = res.url;
        let url = path.split("/");
        this.activeRoute = url[url.length - 1];
        this.storeService.currentRoute.next(this.activeRoute);
      });
  }

  ngOnInit() {
    this.getProfile();
  }

  logoutStore() {
    this.authService.logout().subscribe((data: any) => {
      if (data) {
        this.route.navigate(["/login"]);
      }
    });
  }

  navigatePage(page) {
    this.route.navigate([page]);
  }

  getProfile(){
    this.authService.getProfile().subscribe(res => {
      if(res.isAdmin != undefined) {
        res.isAdmin == true ? this.roles.admin = true : this.roles.staff = true;
      } else {
        let isAdmin =  res.newStaff.isAdmin;
        isAdmin == true ? this.roles.admin = true : this.roles.staff = true;
      }
    })   
  }

  togglBtn(){
    this.isActive = !this.isActive;
  }


  
}

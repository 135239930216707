import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public activeRoute;
  isActive : boolean = false;
  public roles = {
    superAdmin: false,
    admin: false,
    operations: false,
    finance: false,
    support: false
  }
  imagePath ='assets/images/icons/';

  public menu = [
  {
    title: 'Dashboard',
    link: 'dashboard',
    icon: 'dashboard.png',
    roles: {
      superAdmin: true,
      admin: true,
      operations: false,
      finance: false,
      support: false
    }
  },
  {
    title: 'Store',
    link: 'storeList',
    icon: 'store.png',
    roles: {
      superAdmin: true,
      admin: true,
      operations: false,
      finance: false,
      support: false
    }
  },
  {
    title: 'Order',
    link: 'orderList',
    icon: 'list.png'
  },
  {
    title: 'Drivers',
    link: 'driverList',
    icon: 'user.png'
  },
{
    title: 'Manage Shipping Cost',
    link: 'vehicleList',
    icon: 'price.svg'
  },
  {
    title: 'Vehicle List',
    link: 'vehicles',
    icon: 'vehicle.svg'
  },
  {
    title: 'Driver Money',
    link: 'driver-money',
    icon: 'wallet.png'
  },
  {
    title: 'Store Money',
    link: 'store-money',
    icon: 'money.png'
  },
  {
    title: 'Cancellation Orders',
    link: 'orders-cancellation-list',
    icon: 'cancel.png'
  },
  {
    title: 'Manage Countries',
    link: 'countries',
    icon: 'map.svg'
  },
  {
    title: 'Manage Admin Staff',
    link: 'staff',
    icon: 'user.png'
  },
  {
    title: 'rewards',
    link: 'Drivers Rewards',
    icon: 'gift.png'
  },
  {
    title: 'Cash Flow',
    link: 'cash-flow',
    icon: 'money-flow.png'
  },
  {
    title: 'Manage Box Price',
    link: 'box-price',
    icon: 'settings.png'
  }
]  
  
  constructor(private route: Router, private router: ActivatedRoute, private authService: AuthenticationService , public translateService: TranslateService) {
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((res: any) => {
        let path = res.url;
        let arr = path.split("/");
        this.activeRoute = arr[arr.length - 1];
      });
  }

  ngOnInit() {
    this.getProfile();
  }


  
  getProfile(){
    this.authService.getProfile().subscribe(res => {
      console.log
      let permission = res.permission;
      permission === 0 ? this.roles.superAdmin = true : 
      permission === 1 ? this.roles.admin = true : 
      permission === 2 ? this.roles.operations = true :
      permission === 3 ? this.roles.finance = true :
      permission === 4 ? this.roles.support = true : false
    })
  }

  navigateToPage(url) {
    console.log(url)
    let prefix = 'admin'
    this.route.navigate([prefix + '/' +  url]);
  }
  
  logOut() {
    this.authService.logout().subscribe((data) => {
      if (data) {
        this.route.navigate(["/auth/admin/login"]);
      }
    });
  }

  togglBtn(){
    this.isActive = !this.isActive; 
  }

}

export const settingConfig = {
    userStatus:[
        {
            key : 'Disabled',
            value : 0,
            className: 'danger'
        },
        {
            key: 'Enabled',
            value: 1,
            className: 'success'
        },
        {
            key: 'Verification Pending',
            value: 3,
            className: 'warning'
        },
        {
            key: 'Verification Failed',
            value: 4,
            className: 'dark-danger'
        }
    ],
    paymentType:[
        {
            key : 'Debit',
            value : 1,
            className: 'danger'
        },
        {
            key: 'Credit',
            value: 2,
            className: 'success'
        }
    ],
    status:[
        {
            key : 'Enabled',
            value : 0,
            className: 'success'
        },
        {
            key: 'Disabled',
            value: 1,
            className: 'danger'
        }
    ],
    moneyRequest: [
        {
            key: 'Pending',
            value: 0,
            className: 'warning'
        },
        {
            key: 'Rejected',
            value: 1,
            className: 'danger'
        },
        {
            key: 'Accepted',
            value: 2,
            className: 'success'
        }
    ],
    adminStaff: [
        {
            key: 'Super Admin',
            value: 0,
            className: 'primary'
        },
        {
            key: 'Admin',
            value: 1,
            className: 'primary'
        },
        {
            key: 'Operation',
            value: 2,
            className: 'warning'
        },
        {
            key: 'Finance',
            value: 3,
            className: 'success'
        },
        {
            key: 'Support',
            value: 4,
            className: 'danger'
        }
    ],
    paymentStatus: [
        {
            key: 'Pending',
            value: 1,
            className: 'warning'
        },
        {
            key: 'Success',
            value: 2,
            className: 'success'
        },
        {
            key: 'Failed',
            value: 3,
            className: 'danger'
        }
    ],
    phoneLength: [8,8], // minlenth and maxlegth
    phoneRegExp: '^[0-9]*$',
    distanceChargesPerKm: 5,
    cancellationStatus: [
        {
            key: 'Pending',
            value: 0,
            className: 'warning'
        },
    {
            key: 'Penality',
            value: 1,
            className: 'danger'
        },
        {
            key: 'Accepted',
            value: 2,
            className: 'success'
        }
    ],
    countriesStatus: [
        {
            key: 'Offline',
            value: false,
            className: 'danger'
        },
        {
            key: 'Online',
            value: true,
            className: 'success'
        }
    ],
    packageType: [{
            key: "Food",
            value: 1
        },
        {
            key: "Veg",
            value: 2
        },
        {
            key: "Electronic",
            value: 3
        },
    ],

    adminStoreListStatus: [{
            key: "Inactive",
            value: 0
        },
        {
            key: "Active",
            value: 1
        }
    ],

    adminDriverListStatus: [{
            key: "Inactive",
            value: 0,
            className: 'danger'
        },
        {
            key: "Active",
            value: 1,
            className: 'success'
        },
        {
            key: "Verification_Pending",
            value: 2,
            className: 'warning'
        },
        {
            key: "Verification_Failed",
            value: 3,
            className: 'dark-danger'
        }
    ],

    orderStatus: [{
            key: "Placed",
            value: 1,
            className: 'primary'
        },
        {
            key: "Accepted",
            value: 2,
            className: 'primary'
        },
        {
            key: "Completed",
            value: 3,
            className: 'success'
        },
        {
            key: "Cancelled",
            value: 4,
            className: 'danger'
        },
        {
            key: "In Progress",
            value: 5,
            className: 'success'
        },
        {
            key: "Expired",
            value: 6,
            className: 'danger'
        },
        {
            key: "No Driver Found",
            value: 7,
            className: 'danger'
        },
        {
            key: "Paused",
            value: 8,
            className: 'success'
       },
        {
            key: "In Route",
            value: 9,
            className: 'success'
        },
        {
            key: "Ready",
            value: 10,
            className: 'success'
        },
        {
            key: "Picked-up",
            value: 11,
            className: 'success'
        }
    ],
    orderType: [
        {
            key: 'Prepaid',
            value: 0,
            className: 'success'
        },
        {
            key: 'Postpaid',
            value: 1,
            className: 'warning'
        }
    ],
    adminOrderPackageType: [{
            key: "Food",
            value: 1
        },
        {
            key: "Veg",
            value: 2
        },
        {
            key: "Electronic",
            value: 3
        }
    ],
    currentStatus: [{
        key: "Grocery",
        value: 3
    }],
    vehicleTypes: [{
            key: 'Motor Cycle',
            value: 1
        },
        {
            key: 'Car',
            value: 2
        },
        {
            key: 'Van Mini Bus',
            value: 3
        },
        {
            key: 'Any',
            value: 4
        }
    ],
    paymentMethod: [
        {
            key: 'Prepaid',
            value: 0
        },
        {
            key: 'Postpaid',
            value: 1
        }
    ],
    packageSize: [{
            key: 'Small',
            value: 1,
            dimension: '2LX2BX2H - 4LBS',
            price: 2
        },
        {
            key: 'Medium',
            value: 2,
            dimension: '4LX4BX4H - 8LBS',
            price: 4,
        },
        {
            key: 'Large',
            value: 3,
            dimension: '8LX8BX8H - 24LBS',
            price: 8
        }
    ],
    packageStatus: [{
            key: 'Processing',
            value: 1,
        },
        {
            key: 'Success',
            value: 2,
        },
        {
            key: 'Declined',
            value: 3,
        }
    ],
    driverOrderCancellationReason:[
        {
            key: 'Customer Not There',
            value : 1,
        },
        {
            key: 'Invalid Address',
            value: 2
        },
        {
            key: 'Others',
            value: 3
        }
    ],
    timeline: [
        {
           key: 'Order Placed',
           value : 1 
        },
        {
            key: 'Delivery valet Assigned.',
            value : 2,
        },
        {
            key: 'Order Completed',
            value : 3
        },
        {
            key : 'Order Cancelled',
            value : 4,
        },
        {   
            key: 'Order in progress',
            value : 5,
        },
        {
            key: 'Order Expired',
            value : 6
        },
        {
            key : 'No Driver Found',
            value : 7
        },
        {
            key : 'Order Paused',
            value : 8
        },
        {
            key : 'Order in Route',
            value : 9
        },
        {
            key: 'Order is ready',
            value : 10,
        },
        {
            key: 'Order Picked Up',
            value : 11
        }
    ],
   
    getKeysByValue(propertyName, keyValue) {
        let key = '';
        settingConfig[propertyName].forEach(ele => {
            if (ele.value == keyValue) {
                key = ele.key;
            }
        });
        return key;
    },

    getClassNameByValue(propertyName, keyValue) {
        let className = '';
        settingConfig[propertyName].forEach(ele => {
            if (ele.value == keyValue) {
                className = ele.className;
            }
        });
        return className;
    },

}

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public lang : string;

  constructor(private helperService: HelperService, public translate: TranslateService) {
    
    this.helperService.getLang().subscribe(res => {
      this.lang = res;
    })

    translate.addLangs(['en', 'ar']);  
      if (localStorage.getItem('locale')) {  
        const browserLang = localStorage.getItem('locale'); 
        this.lang = browserLang; 
        translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');  
      } else {  
        localStorage.setItem('locale', 'en');  
        translate.setDefaultLang('en');  
      }  
    }

  

  
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  profileName: string;
  
  constructor(private authService: AuthenticationService, 
              public translate: TranslateService,     
              private helperService: HelperService
    ) {
      translate.addLangs(['en', 'ar']);  
      if (localStorage.getItem('locale')) {  
        const browserLang = localStorage.getItem('locale');  
        translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');  
      } else {  
        localStorage.setItem('locale', 'en');  
        translate.setDefaultLang('en');  
      }  
    }

  ngOnInit() {
    this.getProfile();
  }

  getProfile(){
    this.authService.getProfile().subscribe(res => {
      this.profileName = res.name;
    })
  }

  changeLang(language: string) {  
    localStorage.setItem('locale', language);  
    this.translate.use(language);  
    this.helperService.setLang(language)
  }  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Login } from '../../shared/models/login.model';
import { Store } from 'src/app/shared/models/store.model'
const credentialsKey = 'currentUser';
@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient) {
  }
 
  createStore(data: Store): Observable<any> {
    const href = `${environment.createStore}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  verifyOtp(data: any): Observable<any> {
    const href = `${environment.storeOtpVerification}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          this.setUser(data);
        }
        return data;
      })
    );
  }

  resendOtp(data: any): Observable<any> {
    const href = `${environment.resendOtp}`;
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  forgetPassword(data: any): Observable<any> {
    const href = `${environment.forgetPassword}`;
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  changePassword(data: any): Observable<any> {
    const href = `${environment.changePassword}`;
    return this.http.post(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  
  setStoreNewPassword(data: any): Observable<any> {
    const href = `${environment.setNewPassword}`;
    return this.http.post(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }


  storeLogin(data: Login): Observable<any> {
    const href = `${environment.storeLogin}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          this.setUser(data);
        }
        return data;
      })
    );
  }

  adminLogin(data: Login): Observable<any> {
    const href = `${environment.adminLogin}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          this.setUser(data)
        }
        return data.response;
      })
    );
  }

  setUser(data: any): void {
    sessionStorage.setItem(credentialsKey, JSON.stringify(data.response));
  }

  private getUser() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || null;
    return JSON.parse(savedCredentials);
  }

  getProfile(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  getToken() {
    const savedCredentials = this.getUser();
    if(savedCredentials) {
      return savedCredentials && savedCredentials.tokens[0].token ;
    } else  {
      return false;
    }
  }

  logout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    return observableOf(true);
  }
  
  isLogin() {
    if (sessionStorage.getItem(credentialsKey)) {
      return true;
    }else {
      return false
    }
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials["role"]);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
    
      return savedCredentials["isAdmin"];
    } else {
      return false;
    }
  }

  getOtpDetails() {
    if (sessionStorage.getItem("otpDetails")) {
      const otpDetails = sessionStorage.getItem("otpDetails");
      return JSON.parse(otpDetails);
    }
  }


  uploadFiles(data:any): Observable<any> {
    const href = `${environment.uploadFiles}`;
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

 
}


import { urls } from './url';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  
  host: "https://loop.appening.xyz",
  appBaseURL: 'https://loop-web.appening.xyz',
 //appBaseURL: 'http://localhost:4200',
  //appBaseURL: 'https://waselly.app',
  
  googleMapsKey: 'AIzaSyDkYAP8aBQNRo3qelsLEBFs_o9PyqcNCGo',
 // host: "http://localhost:3000",
  ...urls,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { query } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { environment } from "src/environments/environment";
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: "app-store-header",
  templateUrl: "./store-header.component.html",
  styleUrls: ["./store-header.component.css"],
})
export class StoreHeaderComponent implements OnInit {
  public env = environment;
  public storeDetails: any;
  public profileName;
  
  
  constructor(
    private authService: AuthenticationService,
    public translate: TranslateService,
    private helperService: HelperService
  ) {
    translate.addLangs(['en', 'ar']);  
    if (localStorage.getItem('locale')) {  
      const browserLang = localStorage.getItem('locale');  
      translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');  
    } else {  
      localStorage.setItem('locale', 'en');  
      translate.setDefaultLang('en');  
    }  
  }

  ngOnInit() {
    this.getProfile();
   
  }

  getProfile() {
    this.authService.getProfile().subscribe((data) => {
      let res = data;
      this.storeDetails = data.store;
      this.profileName = res.name;
    });
  }

  changeLang(language: string) {  
    localStorage.setItem('locale', language);  
    this.translate.use(language);  
    this.helperService.setLang(language)
  }  
  



}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * Prefixes all requests with `environment.host`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const lang = localStorage.getItem('locale') || null;

    if(request.method === 'GET' && request.url.includes('.json')){
      return next.handle(request);
    }
   
    const headersConfig = {
      Accept: 'application/json',
      'Accept-Language': lang === 'ar' ? 'ar' : 'en',
    };

    const clonedHeaders = new HttpHeaders(headersConfig).set('Accept-Language', headersConfig['Accept-Language']);

    const clonedRequest = request.clone({ 
      url: environment.host + request.url, 
      headers: clonedHeaders 
    });

    return next.handle(clonedRequest);
  }

}

import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class CustomTranslationLoaderService implements TranslateLoader {

  constructor(private http: HttpClient, private baseUrl: string) { 
  }

  getTranslation(lang: string): Observable<any> {
    const translationUrl = `${lang}.json`;
    const fileUrl = 'assets/i18n';
    return this.http.get(`${this.baseUrl}/${fileUrl}/${translationUrl}`);
  }
}

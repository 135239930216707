
/** MODUELS */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

/** END MODUELS */

/** Admin COMPONENTS */
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HeaderComponent } from './layouts/admin-layout/header/header.component';
import { SidebarComponent } from './layouts/admin-layout/sidebar/sidebar.component';
import { LayoutComponent } from './layouts/admin-layout/layout/layout.component';

/** End COMPONENTS */

/* Store components */

import { StoreHeaderComponent } from "./layouts/store-layout/store-header/store-header.component";
import { StoreSidebarComponent } from "./layouts/store-layout/store-sidebar/store-sidebar.component";
import { StoreLayoutComponent } from "./layouts/store-layout/store-layout/store-layout.component";

const COMPONENTS = [
  AuthLayoutComponent,
  HeaderComponent,
  SidebarComponent,
  LayoutComponent,
  StoreLayoutComponent,
  StoreHeaderComponent,
  StoreSidebarComponent,
];

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  SharedModule
];
@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
  ...BASE_MODULES
  ],
  exports: [
    ...BASE_MODULES,
    ...COMPONENTS,
  ],
  providers: [
    LayoutsModule
  ]
})
export class LayoutsModule {
  // static forRoot(): ModuleWithProviders {
  //   return <ModuleWithProviders>{
  //     ngModule: LayoutsModule,
  //   };
  // }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Login, Store } from 'src/app/shared';
const credentialsKey = 'currentUser';

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
  }

  public currentRoute = new BehaviorSubject("");

  public totalOrders = new BehaviorSubject(0);
  public pendingOrders = new BehaviorSubject(0);
  public completedOrders = new BehaviorSubject(0);

  getProfile(id: any): Observable<any> {
    const href = `${environment.storeDetails}`;
    return this.http.post(href, id).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  createOrder(data: any): Observable<any> {
    const href = `${environment.createOrder}`;
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  walletBalance(): Observable<any> {
    const href = `${environment.storeDetails}`;
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data.response; 
        }
      })
    );
  }

  packageList(): Observable<any> {
    const href = `${environment.packageList}`;
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  vehicleOptions(): Observable<any> {
    const href = `${environment.vehicleOptions}`;
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }
  

  createCard(data: any): Observable<any> {
    const href = `${environment.createCard}`;
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  getSavedCards(): Observable<any> {
    const href = `${environment.savedCards}`;
    return this.http.get(href).pipe(
      map((data: any) => {
        return data.response;
      })
    );
  }

  defaultCard(data: any): Observable<any> {
    const href = `${environment.defaultCard}`;
    return this.http.post(href, data).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  deleteCard(cardId: any): Observable<any> {
    const href = `${environment.deleteCard}`;
    return this.http.post(href, cardId).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  storeUpdate(data: Login): Observable<any> {
    const href = `${environment.storeUpdate}`;
    return this.http.post<any>(href, data).pipe(
      map((data) => {
        if(data.status === 'success') {
        
          return data;  
        }
      })
    );
  }

  moneyWithdrawal(payload: any): Observable<any> {
    const href = `${environment.storeMoneyWithdrawal}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        console.log(data);
        if (data.status === "success") {
          return data;
        }
      })
    );
  }


  getStoreDetails(): Observable<any> {
    const href = `${environment.storeDetails}`;
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }


  getOrderList(query?): Observable<any> {
    const href = `${environment.storeOrders}`;
    return this.http.get<any>(href, { params: query }).pipe(
      map((data) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getOrderDetail(id: any): Observable<any> {
    const href = `${environment.orderDetails}`;
    return this.http.get<any>(href + "/" + id).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  setWelcomeModal() {
    localStorage.setItem("welcomeModal", JSON.stringify(true));
  }

  getWelcomeModal() {
    let modalStatus = JSON.parse(localStorage.getItem("welcomeModal")) || null;
    return modalStatus;
  }

    driverRating(data): Observable<any> {
    const href = `${environment.driverRating}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  getOrdersChartsData(query?): Observable<any> {
    const href = `${environment.ordersCharts}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap((data) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getWalletList(query?): Observable<any> {
    const href = `${environment.walletList}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap((data) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  
  createStaff(data: Login): Observable<any> {
    const href = `${environment.createStaff}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  allStaffList(): Observable<any> {
    const href = `${environment.staffList}`;
    return this.http.get<any>(href).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  deleteStaff(payload:any){
    const href = `${environment.deleteStaff}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  staffDetails(id: string) {
    const href = `${environment.staffDetails}`;
    return this.http.get<any>(href + id).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  updateStaff(data: any) {
    const href = `${environment.updateStaff}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  addWalletTopUp(data){
    const href = `${environment.walletTopUp}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  paymentStatus(data) {
    const href = `${environment.paymentStatus}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  vehiclePriceList(): Observable<any> {
    const href = `${environment.vehiclePriceList}`;
    return this.http.get<any>(href).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }

boxPriceList(): Observable<any> {
    const href = `${environment.boxPriceList}`;
    return this.http.get<any>(href).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }

  countryList(): Observable<any> {
    const href = `${environment.countryList}`;
    return this.http.get<any>(href).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }

  trackOrder(orderId:string): Observable<any> {
    const href = `${environment.trackOrder}`;
    return this.http.get<any>(`${href}${orderId}`).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }

  orderCancel(data): Observable<any> {
    const href = `${environment.orderCanel}`;
    return this.http.post<any>(href, data).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }
  
  orderRetry(data): Observable<any> {
    const href = `${environment.orderRetry}`;
    return this.http.post<any>(href, data).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      }))
  }        

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CustomTranslationLoaderService } from '../core/services/custom-translation-loader-service.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from "ngx-bootstrap/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    GooglePlacesDirective,
  ],
  imports: [
  CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
    //  apiKey: "AIzaSyAkq7DnUBTkddWXddoHAX02Srw6570ktx8",
      apiKey: environment.googleMapsKey,
      libraries: ["places"]
    }),
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
        return new CustomTranslationLoaderService(http, environment.appBaseURL)
        },
        deps: [HttpClient],
      }
      }),
  ],  
  exports: [
    GooglePlacesDirective,
    TranslateModule,
    BsDatepickerModule,
    NgxPaginationModule,
    NgbModule,
    FormsModule,
    AgmCoreModule,
    ReactiveFormsModule,
    CommonModule
  ],
  providers: [ BsDatepickerConfig ]
})
export class SharedModule { 


}

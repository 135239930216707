import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor( public translate: TranslateService, private http: HttpClient) { 
    }

  private lang = new BehaviorSubject<string>('en');

  getLang():Observable<string>{
    return this.lang.asObservable(); 
  }
  
  setLang(lang:string){
    this.lang.next(lang)
  }

  getFormattedAddress(place) {
    //@params: place - Google Autocomplete place object
    //@returns: location_obj - An address object in human readable format
 
    let location_obj = {};
    for (let i in place.address_components) {
      let item = place.address_components[i];
      
      location_obj['formatted_address'] = place.formatted_address;
      location_obj['lat'] = place.geometry.location.lat();
      location_obj['long'] = place.geometry.location.lng();
      
      if(item['types'].indexOf("locality") > -1) {
        location_obj['locality'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
        location_obj['admin_area_l1'] = item['short_name']
        location_obj['state'] = item['long_name']
      } else if (item['types'].indexOf("street_number") > -1) {
        location_obj['street_number'] = item['short_name']
      } else if (item['types'].indexOf("route") > -1) {
        location_obj['route'] = item['long_name']
      } else if (item['types'].indexOf("country") > -1) {
        location_obj['country'] = item['long_name']
      } else if (item['types'].indexOf("postal_code") > -1) {
        location_obj['postal_code'] = item['short_name']
      }
    }

    return location_obj;
    
  }

  initLocalization(): void {
    this.translate.addLangs(['en', 'ar']);  
    if (localStorage.getItem('locale')) {  
      const browserLang = localStorage.getItem('locale');  
      this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');  
    } else {  
      localStorage.setItem('locale', 'en');  
      this.translate.setDefaultLang('en');  
    }  
  }

  getDatepickerOptions(){
    return {
      dateInputFormat: 'DD-MM-YYYY',
      containerClass: 'theme-blue',
      showWeekNumbers: true,
    };
  }



}

import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { MapsAPILoader } from '@agm/core';
import { StoreService } from '../core/services/store/store.service';
import { settingConfig } from '../configs';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.css']
})
export class OrderTrackingComponent implements OnInit {
   lat1 :number = 0.0;
   lng1 : number = 0.0;

  lat2 :string;
  lng2 :string;
  
  zoom = 99;
  public orderId :string;
  public orderDetails: any;
  public completedOrder: boolean = false;

  public config = settingConfig;

  public orderPlaced = 25; 
  public accepted = 50; 
  public shipped = 75; 
  public delivered = 100; 
  public status: number;
  public driverIcon = environment.host  + 'assets/images/driver.svg'; 
  public pinIcon = environment.host  + 'assets/images/pin.svg'; 

  getWidth(status: number): number {
    switch (status) {
      case 1:
        return this.orderPlaced;
      case 2:
        return this.accepted;
      case 11:
          return this.shipped;
      case 3:
        return this.delivered;
      default:
        return 0;
    }
  }

  // public renderOptions = {
  //   suppressMarkers: true,
  // }
 
public markerOptions = {
    origin: {
        icon: this.driverIcon,
    },
    destination: {
        icon: this.pinIcon,
    },
}

  public isError : boolean = false;
  mapsApiLoader: any;
  private interval : any;
  constructor(private apiService: StoreService, private router : ActivatedRoute) { }

  ngOnInit() {
    this.router.queryParams.subscribe(res => {
      this.orderId = res['orderId'];
      if(this.orderId !== undefined) {
        this.trackOrder(this.orderId);
        this.interval = setInterval(() => {
          this.trackOrder(this.orderId);
          }, 10000);
      } else {
        this.isError = true;
      }
    })
    }


  trackOrder(id:string){
    this.apiService.trackOrder(id).subscribe(res => {
      let data = res[0];
      console.log(data);
      this.orderId = data._id;
      this.orderDetails = data;
      this.status = data.status;

      // check order is expired
      if(data.status === 6  ) {
        this.isError = true;
      }
      if(data.status === 3) {
        clearInterval(this.interval);
      }
      if(this.status === 3) {
        this.completedOrder = true;
      }

      this.lat1 = data.storeInfo.addressCoordinates.coordinates[0];
      this.lng1 = data.storeInfo.addressCoordinates.coordinates[1];

      if(data.driverLocation.length > 0) {
        this.lat2 = data.driverLocation[0].coordinates[0];
        this.lng2 = data.driverLocation[0].coordinates[1];
      }
    })
  }



}
